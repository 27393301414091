import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';

import { NavLinkDropdown } from '../../../components/bootstrap/Nav';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import useDarkMode from '../../../hooks/useDarkMode';

import { SET_PATIENT, SET_SELECTED_DEPARTMENT } from '../../../reducers/types';
import { getDepartments, setInitialDept } from '../../../actions/departmentActions';
import { staticUrl } from '../../../helpers/helpers';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { dashboardMenu, settingsMenu } from '../../../menu';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ afterChildren }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	// const [viewUpdates, setViewUpdates] = useState(false);

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [openDropdown, setOpenDropdown] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [searchItems, setSearchItems] = useState([]);

	const loggedIn = useSelector((state) => state.auth.loggedIn);
	const user = useSelector((state) => state.auth.user);
	const departments = useSelector((state) => state.department.departments);
	const selectedDept = useSelector((state) => state.profile.selectedDept);
	const patients = useSelector((state) => state.patient.patients);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const unreadNotifications = useSelector((state) => state.profile.unreadNotifications);

	// Search Patient
	const handleSearchPatient = (e) => {
		setSearchInput(e.target.value);
		const key = e.target.value?.toLowerCase?.();
		if (key)
			setSearchItems(
				patients.filter(
					(i) =>
						i.Members?.[0]?.name?.toLowerCase?.().includes(key) ||
						i.Members?.[0]?.phone?.toLowerCase?.().includes(key) ||
						i.Members?.[0]?.registrationNo?.toLowerCase?.().includes(key),
				),
			);
		else setSearchItems([]);
		setOpenDropdown(searchItems.length > 0);
	};

	// Change Organisation
	const handleChangeSelectedDept = (dept) => {
		dispatch(setInitialDept(dept));
		navigate('/');
		localStorage.setItem('init_dept', JSON.stringify(dept));
		// setTimeout(() => window.location.reload(), 100);
	};

	useEffect(() => {
		let mounted = true;

		const fetchOrganisations = () => {
			if (mounted && loggedIn) {
				dispatch(getDepartments());
			}
		};

		fetchOrganisations();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<HeaderRight>
			<div className='row g-3'>
				{/* Patient Search */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle
							hasIcon={false}
							setIsOpen={setOpenDropdown}
							isOpen={openDropdown}>
							<InputGroup>
								<Input
									style={{ width: '120px' }}
									placeholder='Search Patient'
									autoComplete='off'
									onChange={handleSearchPatient}
									value={searchInput}
								/>
								<Button isLight icon='Search' color='primary' onClick={() => {}} />
							</InputGroup>
						</DropdownToggle>
						<DropdownMenu>
							{searchItems.map((i) => (
								<DropdownItem key={i.id}>
									<Button
										onClick={() => {
											setSearchInput('');
											setSearchItems([]);
											dispatch({
												type: SET_PATIENT,
												payload: i,
											});
											navigate(`/patients/${i?.id}/profile`);
											dispatch({
												type: SET_PATIENT,
												payload: i,
											});
											navigate(`/patients/${i?.id}/profile`);
										}}>
										<p>
											{i.Members?.[0].name}
											<br />
											{i.Members?.[0].name}
											<br />
											<span style={{ fontSize: '0.8rem' }}>
												{i.Members?.[0]?.phone}
												&nbsp; Registration:{' '}
												{i.Members?.[0]?.registrationNo}
											</span>
										</p>
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/* Department/Organisation */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								style={{
									fontSize: '12px',
								}}
								icon={selectedDept && !selectedDept?.image ? 'building' : ''}>
								{selectedDept ? (
									<>
										{selectedDept?.image && (
											<img
												className='rounded-circle me-2'
												src={`${staticUrl}/department/${selectedDept?.image}`}
												width={24}
												height={24}
											/>
										)}
										{selectedDept?.name || selectedDept?.Department?.name}
									</>
								) : selectedOrg ? (
									<>
										{selectedOrg?.Organisation?.logo && (
											<img
												className='rounded-circle me-2'
												src={`${staticUrl}/organisations/${selectedOrg?.Organisation?.logo}`}
												width={24}
												height={24}
											/>
										)}
										{selectedOrg?.Organisation?.name}
									</>
								) : (
									'Not Selected'
								)}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							{/* Organisations Dropdown */}
							{
								// (selectedOrg?.userType == 'admin' ||
								// 	selectedOrg?.userType == 'staff') &&

								<DropdownItem>
									<Button
										{...styledBtn}
										style={{
											fontSize: '12px',
										}}
										icon={!selectedOrg?.Organisation?.logo ? 'building' : ''}
										onClick={() => {
											dispatch({
												type: SET_SELECTED_DEPARTMENT,
												payload: null,
											});
											navigate('/');
											setTimeout(() => window.location.reload(), 100);
										}}>
										{selectedOrg?.Organisation?.logo && (
											<img
												className='rounded-circle me-2'
												src={`${staticUrl}/organisations/${selectedOrg?.Organisation?.logo}`}
												width={24}
												height={24}
											/>
										)}
										{selectedOrg?.Organisation?.name}
									</Button>
								</DropdownItem>
							}

							{/* Departments Dropdown */}
							{
								// (selectedOrg?.userType == 'admin' ||
								// 	selectedOrg?.userType == 'staff')

								departments.map((i) => (
									<DropdownItem key={i.id}>
										<Button
											icon={!i?.image ? 'building' : ''}
											onClick={() => handleChangeSelectedDept(i)}>
											{i?.image && (
												<img
													className='rounded-circle me-3'
													src={`${staticUrl}/department/${i?.image}`}
													width={24}
													height={24}
												/>
											)}
											{i.name}
										</Button>
									</DropdownItem>
								))
							}
						</DropdownMenu>
					</Dropdown>
				</div>

				{selectedOrg?.userType != 'staff' && !user?.employeeType?.includes?.('student') && (
					<>
						{/* Settings */}
						<div className='col-auto'>
							<Dropdown>
								<DropdownToggle hasIcon={false}>
									<NavLinkDropdown style={{ color: 'black', padding: 0 }}>
										<Button
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											isDisable
											icon='Settings'></Button>
									</NavLinkDropdown>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd>
									{Object.keys(settingsMenu).map((subItem) => (
										<DropdownItem>
											<NavLink to={settingsMenu[subItem].path}>
												{settingsMenu[subItem].icon && (
													<img
														className='me-2'
														width='20px'
														height='20px'
														src={settingsMenu[subItem].icon}
													/>
												)}
												<span style={{ color: 'black' }}>
													{settingsMenu[subItem].text}
												</span>
											</NavLink>
										</DropdownItem>
									))}
								</DropdownMenu>
							</Dropdown>
						</div>
						{/*	Notifications */}
						<div className='col-auto'>
							<Button
								{...styledBtn}
								icon='Notifications'
								onClick={() =>
									navigate(
										`/${dashboardMenu.settings.subMenu.pendingRequests.path}`,
									)
								}
							/>
							{unreadNotifications && (
								<span
									className='position-absolute badge border border-2 border-light rounded-circle bg-primary p-2'
									style={{ right: '74px' }}>
									<span className='visually-hidden'>Unread Notifications</span>
								</span>
							)}
						</div>
					</>
				)}

				{afterChildren}
			</div>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
