import React from 'react'

const InsulinBot = () => {
  return (
    <div className="chatbot-dropdown">
        InsulinBot
    </div>
  )
}

export default InsulinBot